import * as React from "react";
import { Parallax } from "react-parallax";

import image from "../images/home.jpg";
import Layout from "../components/layout";
import Section from "../components/section";
import Typography from "../components/typography";
import { Row, Container, Col } from "react-bootstrap";
import ScheduleForm from "../components/scheduleForm";

const HomePage = () => {
	return (
		<Layout nextPage={{ link: "/banks", text: "Banks & Credit Unions" }}>
			<Section className="partner-page-container" color="light" staticHeight>
				<Parallax
					className="d-flex justify-content-center align-items-center h-100"
					bgImage={image}
					strength={750}>
					<Row className="d-flex h-100">
					<Col className= "PartnerCol">
							<Typography type="head" className="nom">
								Connections for
								Banks &amp; Credit Unions
								through Fundingverse
							</Typography>
							<Typography type="head" className="hide-on-desktop text-center">
                			Bank &amp; Credit Union Connections
              				</Typography>
						</Col>	  
						<Col>
							<ScheduleForm to="/" />
						</Col>
					</Row>
				</Parallax>
			</Section>
			<Section size="sm" bg="primary" color="light">
				<Container className="py-5">
					<Typography type="title">
						Your future access point to new lending &amp; deposit relationships:
					</Typography>
					<ul className="text-light">
						<li className="text-light">
							Select from a list of current lending opportunities that match
							your lending criteria &amp; geography!
						</li>
						<li className="text-light">
							We match borrowers eligible for bank loans with bank partners that
							are the right “fit” for them in asset size and loan types.
						</li>
						<li className="text-light">
							Small to midsize businesses utilize Fundingverse for a singular
							search process to efficiently find funding for when they need it,
							and how they need it.
						</li>
						<li className="text-light">
							Every business deserves to be treated like a VIP, but not everyone
							gets that treatment at the large banks they currently bank at.
						</li>
						<li className="text-light">
							Often times borrowers move their deposits and operating accounts
							to our bank partners for the right fit.
						</li>
					</ul>
				</Container>
			</Section>
			<Section size="md" color="light" bg="dark">
				<Container className="text-center py-5 h-100">
					<Typography type="title">
						Benefits of A Fundingverse Partnership
					</Typography>
					<div className="d-flex h-100 align-items-center">
						<ul className="text-start text-light">
							<li className="text-light">
								Create a database of future customers &amp; relationships for
								free.
							</li>
							<li className="text-light">
								Enhance the ways you can help businesses in your communities.
							</li>
							<li className="text-light">
								If you can’t help your customers, connect them with reputable
								alternative lenders in the mean time.
							</li>
							<li className="text-light">
								We only partner with professional, reputable bank &amp; non-bank
								lenders.
							</li>
						</ul>
					</div>
				</Container>
			</Section>
		</Layout>
	);
};

export default HomePage;
