import * as React from "react";
import {
  Formik,
  FormikProps,
  Field,
  Form,
  ErrorMessage,
} from "formik";
import { Card, FormGroup } from "react-bootstrap";
import InputMask from "react-input-mask";
import Typography from "./typography";
import { encode } from "./iframe";

interface ScheduleFormValues {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
}

interface ScheduleFormProps {
  to: string;
}

export default function ScheduleForm({ to }: ScheduleFormProps) {
  const initialValues: ScheduleFormValues = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  };
  return (
    <div className="scheduleForm m-auto" style={{ maxWidth: "525px" }}>
      {/* <div
				className="calendly-inline-widget"
				style={{ minWidth: "300px", height: "630px" }}>
				<iframe src={iframe} width="100%" height="100%" frameBorder="0" />
			</div> */}
      <Formik
        initialValues={initialValues}
        validate={(values) => {
          const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
          const errors: any = {};
          if (!values.firstName) {
            errors.firstName = "First Name Required";
          }
          if (!values.lastName) {
            errors.lastName = "Last Name Required";
          }
          if (!values.email || !emailRegex.test(values.email)) {
            errors.email = "Valid Email Required";
          }
          return errors;
        }}
        onSubmit={(values, actions) => {
          fetch("/", {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: encode({ "form-name": "scheduleForm", ...values }),
          })
            .then(() => {
              alert("Thank You! Your Request Has Been Submitted.");
              window.location.href = `https://calendly.com/davidge/meeting?name=${values.firstName}%20${values.lastName}&email=${values.email}&a1=1${values.phone}`;
              actions.resetForm();
              actions.setSubmitting(false);
            })
            .catch(() => {
              alert("Error, Please Try Filling Out The Form Again");
            });
        }}
      >
        {({ values, handleChange, handleBlur }: FormikProps<any>) => (
          <Form name="scheduleForm" data-netlify={true} className="card">
            <Card.Header className="g-card-header text-center">
              <Typography className="fw-bold" type="paragraph" color="light">
                Connect With Us Today!
              </Typography>
            </Card.Header>
            <Card.Body>
              <div className="d-flex mb-3 h-100">
                <FormGroup
                  style={{ marginRight: ".75rem" }}
                  className="flex-full"
                >
                  <label htmlFor="firstName">First Name</label>
                  <Field
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    className="form-control"
                  />
                  <small className="text-danger">
                    <ErrorMessage name="firstName" />
                  </small>
                </FormGroup>
                <FormGroup className="flex-full">
                  <label htmlFor="lastName">Last Name</label>
                  <Field
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    className="form-control"
                  />
                  <small className="text-danger">
                    <ErrorMessage name="lastName" />
                  </small>
                </FormGroup>
              </div>
              <FormGroup className="mb-4">
                <label htmlFor="email">Email</label>
                <Field
                  id="email"
                  name="email"
                  placeholder="Email"
                  className="form-control"
                />
                <small className="text-danger">
                  <ErrorMessage name="email" />
                </small>
              </FormGroup>
              <FormGroup className="mb-4">
                <label htmlFor="phone">Phone Number</label>
                <Field
                  name="phone"
                  label="Phone Number"
                  render={({ field }: any) => (
                    <InputMask
                      {...field}
                      mask="(999) 999-9999"
                      placeholder="Phone number"
                      type="tel"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      className="form-control"
                    />
                  )}
                />
              </FormGroup>
              <div className="text-end">
                <button type="submit">Schedule Meeting</button>
              </div>
            </Card.Body>
          </Form>
        )}
      </Formik>
    </div>
  );
}
